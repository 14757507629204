<template>
    <template v-if="isAccess">
        <div class="card">
            <Dialog header="删除警告" v-model:visible="deleteAccountDialog" :style="{width: '350px'}" :modal="true">
            <div class="confirmation-content">
                <span>确定要删除账号：{{ this.selectedUserId }} 吗?</span>
            </div>
            <template #footer>
                <Button label="确认" @click="deleteAccount"/>
                <Button label="取消" @click="()=>{this.deleteAccountDialog=false;}" class="p-button-text"/>
            </template>
        </Dialog>
            <Dialog header="添加子账号" v-model:visible="addUserDialog" :modal="true">
                <div style="display:flex;align-items:center;padding: 0.5rem;"><label>账号:</label><InputText type="text" v-model="userId" class="p-inputtext-lg ml-3"  placeholder="请输入账号" /></div>
                <div style="display:flex;align-items:center;padding: 0.5rem;"><label>密码:</label><InputText type="text" v-model="pwd" class="p-inputtext-lg ml-3"  placeholder="请输入密码" /></div>
                <template #footer>
                    <Button label="确定" @click="addSubAccount" />
                    <Button label="取消" @click="()=>{this.addUserDialog=false;}" class="p-button-text"/>
                </template>
            </Dialog>

            <Dialog header="管理权限" v-model:visible="managePermissionDialog" :modal="true">
                <DataTable stripedRows :value="permissionList" responsiveLayout="scroll" scrollable style="width: 50rem;">
                    <Column field="name" header="其他"></Column>
                    <Column field="checked" header="权限">
                        <template #body="{data}">
                            <RadioButton :value="true" v-model="data.checked" />是 
                            <RadioButton class="ml-3" :value="false" v-model="data.checked" />否 
                        </template>
                    </Column>
                </DataTable>
                <DataTable stripedRows :value="storeList" responsiveLayout="scroll" scrollable style="width: 50rem;">
                    <Column field="name" header="店铺"></Column>
                    <Column field="checked" header="权限">
                        <template #body="{data}">
                            <RadioButton :value="true" v-model="data.checked" />是 
                            <RadioButton class="ml-3" :value="false" v-model="data.checked" />否 
                        </template>
                    </Column>
                </DataTable>
                <template #footer>
                    <Button label="保存" @click="savePermission" />
                    <Button label="取消" @click="()=>{this.managePermissionDialog=false;}" class="p-button-text"/>
                </template>
            </Dialog>
            <div class="mb-3" style="justify-content:space-between;display: flex;border-radius: 4px">
                <Button icon="pi pi-plus-circle" label="添加子账号" @click="()=>{this.addUserDialog=true;}" />
            </div>
        <DataTable stripedRows :value="userList" responsiveLayout="scroll" scrollable scrollHeight="calc(100vh - 16.5rem)">
            <Column field="userId" header="账户ID"></Column>
            <Column field="stores" header="店铺权限">
                <template #body="{data}">
                    {{data.stores.map(sellerId=>{return storeAll[sellerId]}).join('、')}}
                </template>
            </Column>
            <Column field="permission" header="其他权限">
                <template #body="{data}">
                    {{data.permissions.map(permissionId=>{return permissionAll[permissionId]}).join('、')}}
                </template>
            </Column>
            <Column header="操作">
                <template #body="{data}">
                    <Button label="管理权限" @click="showPermissionDialog(data.userId)" class="p-button-text"/>
                    <Button label="删除账号" @click="()=>{this.selectedUserId=data.userId;this.deleteAccountDialog=true;}" class="p-button-text"/>
                </template>
            </Column>
        </DataTable>
      </div>
    </template>
    <template v-else>
        <Access />
    </template>
</template>

<script>
import Access from '../components/Access.vue';
import Service from '../service/Service';
import md5 from 'js-md5';
    export default {
        data(){
            return{
                userList:[],
                isAccess:false,
                addUserDialog:false,
                managePermissionDialog:false,
                user:null,
                userId:null,
                pwd:null,
                permissionList:[],
                storeList:[],
                storeAll:{},
                permissionAll:{},
                selectedUserId:null,
                deleteAccountDialog:false
            }
        },
        created() {
        this.service = new Service();
    },
        mounted(){
			this.user=JSON.parse(localStorage.getItem('user'));
            if(this.user.ManageUser=='1'){
                this.isAccess=true;
            }
            else{
                return;
            }
            this.getUserList();
        },
        components: {
		'Access': Access
	},
        methods:{
            getUserList(){
                var url='system/getsubaccountlist.php';
                this.service.sendHttp(url).then(res=>res.json()).then(data => {
                    if(data.result=='success'){
                        this.userList=data.users;
                        this.storeAll=data.storeAll;
                        this.permissionAll=data.permissionAll;
                    } 
                });
            },
            addSubAccount(){
                if(!this.userId){
                    alert('账号不能为空！');
                    return;
                }
                if(!this.pwd){
                    alert('密码不能为空！');
                    return;
                }
                var url='system/addsubaccount.php';
                const formData = new FormData();
                formData.append('userId', this.userId);
                formData.append('pwd', md5(this.pwd));
                this.service.sendHttp(url,'POST',formData).then(res=>res.json()).then(data => {
                if(data.result=='success'){
                    this.userId='';
                    this.pwd='';
                    alert('添加成功！');
                    this.addUserDialog=false;
                    this.getUserList();
                }
                else{
                    alert('添加失败！');
                }
             });
            },
            showPermissionDialog(userId){
                this.selectedUserId=userId;
                this.permissionList=[];
                this.storeList=[];
                for(let key in this.permissionAll){
                    let permission={
                        permissionId:key,
                        name:this.permissionAll[key],
                        checked:false
                    };
                    this.permissionList.push(permission);
                }
                for(let key in this.storeAll){
                    let store={
                        sellerId:key,
                        name:this.storeAll[key],
                        checked:false
                    };
                    this.storeList.push(store);
                }
                var user=this.userList.filter(user=>user.userId==userId)[0];
                user.stores.forEach(sellerId=>{
                    this.storeList.forEach(store=>{
                        if(store.sellerId==sellerId){
                            store.checked=true;
                        }
                    });
                });
                user.permissions.forEach(permissionId=>{
                    this.permissionList.forEach(p=>{
                        if(p.permissionId==permissionId){
                            p.checked=true;
                        }
                    });
                });
                this.managePermissionDialog=true;
            },
            savePermission(){
                let stores=this.storeList.filter(s=>s.checked==true);
                let sellerIds=stores.map(store =>{return store.sellerId});
                let permissions=this.permissionList.filter(p=>p.checked==true);
                var permissionIds=permissions.map(p=>{return p.permissionId});
                var url='system/savepermission.php';
                const formData = new FormData();
                formData.append('userId', this.selectedUserId);
                formData.append('sellerIds', JSON.stringify(sellerIds));
                formData.append('permissionIds', JSON.stringify(permissionIds));
                this.service.sendHttp(url,'POST',formData).then(res=>res.json()).then(data => {
                if(data.result=='success'){
                    alert('保存成功！');
                    this.managePermissionDialog=false;
                    this.userList.forEach(user=>{
                        if(user.userId==this.selectedUserId){
                            user.stores=sellerIds;
                            user.permissions=permissionIds;
                        }
                        
                    });
                }
                else{
                    alert('保存失败！');
                }
             });
            },
            deleteAccount(){
                var url='system/deleteaccount.php';
                const formData = new FormData();
                formData.append('userId', this.selectedUserId);
                this.service.sendHttp(url,'POST',formData).then(res=>res.json()).then(data => {
                    if(data.result=='success'){
                        let userList=this.userList.filter(user=>user.userId!=this.selectedUserId);
                        this.userList=userList;
                        this.deleteAccountDialog=false;
                    }
                    else{
                        alert('删除失败！');
                    }
                });
            }
        }
    }
</script>